$theme: (
    'primary': #193256,
    'secondary': #abc7d9,
    'third': #04598c,

    'primaryLight': #68BDF0,
    'primaryDark': #193256,
    'secondaryLight': #193256,
    'secondaryDark': #04598c,
    'thirdLight': #7BB8D1,
    'thirdDark': #04598c,

    'success': #2ba44b,
    'error': #f63719,
    'warning': #e4a300,
    'info': #006ba4,

    'dark': #262835,
    'light': #F1A542,
    'grey': #DBDFE1,
    'greyDark': #BAC1C5,
    'green': #51BEA5,
);

:export {
    @each $key, $value in $theme {
        #{unquote($key)}: $value;
    }
}