@use "sass:map";
@import "colors";

@import "bulma/sass/utilities/_all";

@import "bulma/sass/base/_all";

@import "bulma/sass/elements/container";
@import "bulma/sass/elements/content";

@import "bulma/sass/form/shared";
@import "bulma/sass/form/input-textarea";
@import "bulma/sass/form/tools";

@import "bulma/sass/components/card";

@import "bulma/sass/grid/columns";

html {
	position: relative;
	min-height: 100% !important;
	width: 100vw;
	overflow-y: auto;
	overflow-x: hidden !important;
}

body {
	margin: 0;
	padding-right: 0 !important;
	min-height: 100% !important;
	overflow-x: hidden !important;
	overflow-y: auto !important;
	color: black;
	font-family: 'Roboto', sans-serif;
}

#root {
	display: flex;
	flex-direction: column;
}

/* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: auto;
    scrollbar-color: map.get($theme, "greyDark") white;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 13px;
	height: 13px;
  }

  *::-webkit-scrollbar-track {
    background: white;
  }

  *::-webkit-scrollbar-thumb {
    background-color: map.get($theme, "greyDark");
    border-radius: 10px;
    border: 2px solid white;
  }

.-sort-asc,
.-sort-desc {
	padding-right: 20px !important;
	box-shadow: none !important;
}

.-sort-asc::before {
	content: "\25B2";
	position: absolute;
	right: 1.2rem;
	top: 0.65rem;
}

.-sort-desc::before {
	content: "\25BC";
	position: absolute;
	right: 1.2rem;
	top: 0.65rem;
}

.no-before::before {
	content: none;
}

.no-before {
	padding-top: 5px !important;
	padding-bottom: 0 !important;
	padding-right: 5px !important;
}

.ReactTable .rt-th {
	overflow: visible;
}

.-noborder {
	border: none!important;
}

@media screen and (min-width: 1024px) {
	.ReactTable {
		max-width: 960px !important;
	}
}

@media screen and (min-width: 1216px) {
	.ReactTable {
		max-width: 1152px !important;
	}
}

@media screen and (min-width: 1408px) {
	.ReactTable {
		max-width: 1344px !important;
	}
}

.no-pad {
	max-width: none;
	margin-left: 3%;
	margin-right: 3%;
}

.-previous,
.-center,
.-next {
	flex: unset !important;
	align-self: center !important;
}

.-pagination {
	justify-content: center !important;
	margin: unset !important;
}

.ReactTable.-striped {
	.rt-tr {
		&.-even {
			background-color: map.get($theme, "secondaryLight") !important;

			&:hover {
				background-color: map.get($theme, "secondary") !important;
			}
		}

		&.-odd {
			background-color: white !important;

			&:hover {
				background-color: map.get($theme, "secondaryLight") !important;
			}
		}
	}

	.rt-tr-group {
		border: none !important;
	}

	.rt-thead {
		border: none !important;
		background-color: map.get($theme, "secondary") !important;
	}
}

text.selector-text {
	fill: black !important;
}

.header-container {
	margin: 0 auto !important;
	width: 100%;
	height: 100%;
	padding-left: 0!important;
	padding-right: 0!important;
	min-height: auto;
}

@media screen and (min-width: 1024px) {
	.header-container {
		max-width: 960px;
	}
}

@media screen and (min-width: 1216px) {
	.header-container {
		max-width: 1152px;
	}
}

@media screen and (min-width: 1408px) {
	.header-container {
		max-width: 1344px;
	}
}

.small-icon span svg {
	padding-left: 0.1em;
	padding-right: 0.1em;
}

#collapse::before {
	border-bottom: 7px solid white;
}

#collapse::after {
	border-bottom: unset;
}

#expand::before {
	border-top: 7px solid white;
}

#expand::after {
	border-top: unset;
}

.disabled-link {
	pointer-events: none;
}

.no-margin-bottom {
	margin-bottom: 0px !important;
}

#due_date {
	height: 1.5rem;
}

.filepond--wrapper {
	display: none;
}

.suggestions-dropdown {
	z-index: 1;
	max-height: 100%;
	overflow: auto;
}

.smooth-dnd-ghost {
	> div {
		cursor: grabbing !important;
	}
}

.marked-text {
	background-color: map.get($theme, "light");
}
